/* eslint-disable */
import axios from 'axios';
import { errorToast, getTokenBasedOnRole, getDeviceToken, cleanLocalStorageBasedOnRole } from '../utils/helper';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://192.168.1.215:8000/admin/v1';

let tokenExpiredErrorShown = false;

const GetApi = (tag = '', isHeader = false, isWhiteLabelAdminUserApi = false) => {
  return axios
    .get(BASE_URL + tag, {
      headers: isHeader
        ? {
            Authorization: getTokenBasedOnRole(isWhiteLabelAdminUserApi),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const PostApi = (tag = '', reqBody, isHeader = false, flag, isWhiteLabelAdminUserApi = false) => {
  let flagCheck = flag ? 'multipart/form-data; boundary=----WebKitFormBoundaryueI4YvrqiXxUgVGA' : 'application/json';
  return axios
    .post(BASE_URL + tag, reqBody, {
      headers: isHeader
        ? {
            'Content-Type': flagCheck,
            accept: 'application/json',
            Authorization: getTokenBasedOnRole(isWhiteLabelAdminUserApi),
          }
        : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
      return e.response?.data;
    });
};

const DeleteApi = (tag = '', isHeader = false, body = null, isWhiteLabelAdminUserApi = false) => {
  const axiosConfig = {
    headers: isHeader
      ? {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: getTokenBasedOnRole(isWhiteLabelAdminUserApi),
          deviceToken: getDeviceToken(),
        }
      : {},
  };

  if (body !== null) {
    axiosConfig.data = body;
  }

  return axios
    .delete(BASE_URL + tag, axiosConfig)
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const PutApi = (tag = '', reqBody, isHeader, isWhiteLabelAdminUserApi = false) => {
  const headers = {
    accept: 'application/json',
    Authorization: getTokenBasedOnRole(isWhiteLabelAdminUserApi),
  };
  return axios
    .put(BASE_URL + tag, reqBody !== null && reqBody, {
      headers: isHeader ? headers : {},
    })
    .then((data) => {
      if (data.status === 200) {
        return data;
      } else {
        return data;
      }
    })
    .catch(async (e) => {
      ErrorHandler(e);
    });
};

const ErrorHandler = async (e) => {
  if (e.response?.data?.detail?.message) {
    if (e.response?.data?.detail?.code === 401) {
      if (!tokenExpiredErrorShown) {
        tokenExpiredErrorShown = true;
        errorToast(e.response?.data?.detail?.message);
        sessionStorage.clear();
        if (window.location.pathname.includes('/whitelabel/admin')) {
          setTimeout(() => {
            cleanLocalStorageBasedOnRole('whiteLabelAdmin');
            window.location.href = '/whitelabel/admin/login';
          }, 1000);
        } else {
          setTimeout(() => {
            cleanLocalStorageBasedOnRole('superadmin');
            window.location.href = '/login';
          }, 1000);
        }
      }
    } else {
      errorToast(e.response?.data?.detail?.message);
    }
  } else if (e.response?.status === 500) {
    errorToast(e.response?.data || 'Internal server error');
  } else if (e?.code === 'ERR_NETWORK') {
    errorToast(e?.message || 'Network Error');
  } else {
    errorToast(e.response?.data?.meta?.message || 'Something went wrong');
  }
};

const buildQueryString = (params) => {
  const queryString = params
    .filter(
      (p) =>
        // typeof p?.value === "boolean" ? p?.value !== null : p?.value
        typeof p?.value === 'boolean' || p?.value === 0 || p?.value,
    )
    .map((p) => `${encodeURIComponent(p?.key)}=${encodeURIComponent(p?.value)}`)
    .join('&');

  return queryString.length > 0 ? `?${queryString}` : '';
};

export const Api = {
  //LRF FLOW APIs
  login: (reqBody) => PostApi('/auth/admin-login', reqBody, true, true),
  forgotPassword: (reqBody) => PostApi('/auth/admin-forgot-password', reqBody),
  resetPassword: (reqBody) => PostApi('/auth/admin-new-password', reqBody),
  changePassword: (reqBody) => PutApi('/auth/admin-change-password', reqBody, true),
  changePasswordSpecifiToOrg: (reqBody) => PutApi('/whitelabel/auth/admin-change-password', reqBody, true),

  //Sub Admin Menu APIs
  getAdmins: (page, perPage, searchKey, sortBy, sortOrder, userType, status) => {
    const params = [
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
      { key: 'user_type', value: userType },
      { key: 'status', value: status },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/auth/get-admin-list${tmpUrl}`;

    return GetApi(newUrl, true);
  },
  getAdminsSpecifiToOrg: (page, perPage, searchKey, sortBy, sortOrder, userType, status) => {
    const params = [
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
      { key: 'user_type', value: userType },
      { key: 'status', value: status },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/auth/get-admin-list${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },
  addAdmin: (reqBody) => PostApi('/auth/create-admin-user', reqBody, true),
  updateAdmin: (reqBody) => PutApi('/auth/update-admin', reqBody, true),
  deleteAdmin: (adminId) => DeleteApi(`/auth/delete-admin?admin_id=${adminId}`, true),
  switchAdminStatus: (reqBody) => PostApi('/auth/active-inactive-admin', reqBody, true),
  getAdminDetail: (adminId) => GetApi(`/auth/get-admin-info?admin_id=${adminId}`, true),
  bulkAdminUpdate: (reqBody) => PutApi('/auth/change_admin_status', reqBody, true),
  addAdminSpecifiToOrg: (reqBody) =>
    PostApi('/whitelabel/auth/create-whitelabel-admin-user', reqBody, true, undefined, true),
  updateAdminToUser: (userId) =>
    PostApi(`/auth/convert-superadmin-to-user?user_id=${userId}`, null, true, false, false),
  updateAdminToUserSpecifiToOrg: (userId) =>
    PostApi(`/whitelabel/auth/convert-whitelabeladmin-to-user?user_id=${userId}`, null, true, false, true),

  //User APIs
  switchUserStatus: (reqBody) => PostApi('/user/active-inactive-user', reqBody, true),

  getUsersList: (reqBody) => PostApi('/user/get_user_list', reqBody, true),

  getUserDetail: (id) => GetApi(`/user/get-user-detail-profile?user_id=${id}`, true),
  getWhitelabelUserDetail: (id) => GetApi(`/whitelabel/user/get-user-detail-profile?user_id=${id}`, true, true),
  deleteUser: (userId) => DeleteApi(`/user/delete_user?user_id=${userId}`, true),
  deleteUserSpecifiToOrg: (userId) => DeleteApi(`/whitelabel/user/delete_user?user_id=${userId}`, true, null, true),
  bulkUserUpdate: (reqBody) => PutApi('/user/change_user_status', reqBody, true),
  bulkUserUpdateSpecifiToOrg: (reqBody) => PutApi('/whitelabel/user/change_user_status', reqBody, true, true),
  followingList: (user_id) => GetApi(`/user/user-following-list?user_id=${user_id}`, true),
  followersList: (user_id) => GetApi(`/user/user-follower-list?user_id=${user_id}`, true),
  getUserCreditHistory: (userId, sortBy, sortOrder, limit, page, searchKey) => {
    const params = [
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'searchKey', value: searchKey },
      { key: 'user_order_by', value: sortBy },
      { key: 'order_flag', value: sortOrder },
      { key: 'user_id', value: userId },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/user/user-credit-history${tmpUrl}`;
    return GetApi(newUrl, true);
  },
  getUserCreditHistorySpecifiToOrg: (userId, sortBy, sortOrder, limit, page, searchKey) => {
    const params = [
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'searchKey', value: searchKey },
      { key: 'user_order_by', value: sortBy },
      { key: 'order_flag', value: sortOrder },
      { key: 'user_id', value: userId },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/user/user-credit-history${tmpUrl}`;
    return GetApi(newUrl, true, true);
  },
  addEditUserCredit: (reqBody) => PostApi('/user/manage-user-credit', reqBody, true),
  addEditUserCreditSpecifiToOrg: (reqBody) =>
    PostApi('/whitelabel/user/manage-user-credit', reqBody, true, undefined, true),

  // Network APIs
  createNetwork: (reqBody) => PostApi('/network/create-network', reqBody, true),
  createNetworkInOrg: (reqBody) => PostApi('/whitelabel/network/create-network', reqBody, true, undefined, true),
  updateNetworkApplicationForm: (reqBody) => {
    if (window?.location?.pathname == '/whitelabel/admin/networks/view') {
      return PutApi('/whitelabel/network/update-network-application-form', reqBody, true, true);
    } else {
      return PutApi('/network/update-network-application-form', reqBody, true);
    }
  },
  createNetworkApplicationForm: (reqBody) => {
    if (window?.location?.pathname == '/whitelabel/admin/networks/view') {
      return PostApi('/whitelabel/network/create-network-application-form', reqBody, true, undefined, true);
    } else {
      return PostApi('/network/create-network-application-form', reqBody, true, undefined);
    }
  },
  getNetworkApplicationForm: (networkId) => {
    if (window?.location?.pathname == '/whitelabel/admin/networks/view') {
      return GetApi(`/whitelabel/network/get-network-application-form?network_id=${networkId}`, true, true);
    } else {
      return GetApi(`/network/get-network-application-form?network_id=${networkId}`, true);
    }
  },
  updateNetwork: (reqBody) => PutApi('/network/update-network', reqBody, true),
  updateNetworkInOrg: (reqBody) => PutApi('/whitelabel/network/update-network', reqBody, true, true),
  bulkNetworkUpdate: (reqBody) => PutApi('/network/change-networks-status', reqBody, true),
  bulkNetworkUpdateSpeificToOrg: (reqBody) => PutApi('/whitelabel/network/change-networks-status', reqBody, true, true),
  getAllNetworks: (page, perPage, searchKey, sortBy, sortOrder, filterQuery) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
      { key: 'status', value: filterQuery },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/network/get-network-list${tmpUrl}`;

    return GetApi(newUrl, true);
  },
  getAllNetworksInOrg: (page, perPage, searchKey, sortBy, sortOrder, filterQuery) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
      { key: 'status', value: filterQuery },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/network/get-network-list${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },

  getAllAdminNetworks: (page, perPage, searchKey, sortBy, sortOrder, filterQuery) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
      { key: 'status', value: filterQuery },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/network/get-admin-network-list${tmpUrl}`;

    return GetApi(newUrl, true);
  },

  getAllAdminNetworkInOrg: (page, perPage, searchKey, sortBy, sortOrder, filterQuery) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
      { key: 'status', value: filterQuery },
    ];
    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/network/get-admin-network-list${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },

  getAllInterestsList: (searchKey) => GetApi(`/network/interests?search=${searchKey}`, true),
  getAllInterestsListSpecificToOrg: (searchKey) =>
    GetApi(`/whitelabel/network/interests?search=${searchKey}`, true, true),

  getAllSkillsList: (searchKey) => GetApi(`/network/skills?search=${searchKey}`, true),
  getAllSkillsListSpecificToOrg: (searchKey) => GetApi(`/whitelabel/network/skills?search=${searchKey}`, true, true),

  getAllUsersList: (page, limit, search) =>
    GetApi(`/network/get-network-user?page=${page}&limit=${limit}&searchKey=${search}`, true),
  getAllUsersListInOrg: (page, limit, search) =>
    GetApi(`/whitelabel/network/get-network-user?page=${page}&limit=${limit}&searchKey=${search}`, true, true),
  deleteNetwork: (networkId) => DeleteApi(`/network/delete-network?network_id=${networkId}`, true),
  deleteNetworkInOrg: (networkId) =>
    DeleteApi(`/whitelabel/network/delete-network?network_id=${networkId}`, true, null, true),
  deleteNetworkInOrg: (networkId) =>
    DeleteApi(`/whitelabel/network/delete-network?network_id=${networkId}`, true, null, true),
  switchNetwork: (reqBody) => PostApi('/network/active-inactive-network', reqBody, true),
  switchNetworkInOrg: (reqBody) =>
    PostApi('/whitelabel/network/active-inactive-network', reqBody, true, undefined, true),
  getNetworkDetail: (networkId) => GetApi(`/network/get-network-detail?network_id=${networkId}`, true),
  getNetworkDetailOfOrg: (networkId) =>
    GetApi(`/whitelabel/network/get-network-detail?network_id=${networkId}`, true, true),

  getNetworkMembers: (reqBody) => PostApi('/network/get-network-member', reqBody, true),
  getNetworkMembersInOrg: (reqBody) =>
    PostApi('/whitelabel/network/get-network-member', reqBody, true, undefined, true),
  getNetworkFollowers: (reqBody) => PostApi('/network/get-network-follower', reqBody, true),
  getNetworkFollowersInOrg: (reqBody) =>
    PostApi('/whitelabel/network/get-network-follower', reqBody, true, undefined, true),

  getNetworkStats: (networkId, fDate, tDate) => {
    const params = [
      { key: 'network_id', value: networkId },
      { key: 'from_date', value: fDate },
      { key: 'to_date', value: tDate },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/network/get-network-stats${tmpUrl}`;

    return GetApi(newUrl, true);
  },
  getNetworkStatsSpecificToOrg: (networkId, fDate, tDate) => {
    const params = [
      { key: 'network_id', value: networkId },
      { key: 'from_date', value: fDate },
      { key: 'to_date', value: tDate },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/network/get-network-stats${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },

  // Activity APIs
  getAllActivities: (reqBody) => PostApi('/activities/get-activity-list', reqBody, true),
  getAllActivitiesInOrg: (reqBody) =>
    PostApi('/whitelabel/activities/get-activity-list', reqBody, true, undefined, true),
  getContent: (contentId) => GetApi(`/activities/get-content?content_id=${contentId}`, true),
  getContentInOrg: (contentId) => GetApi(`/whitelabel/activities/get-content?content_id=${contentId}`, true, true),
  deleteContent: (reqBody) => DeleteApi(`/activities/delete-content`, true, reqBody),
  deleteContentInOrg: (reqBody) => DeleteApi(`/whitelabel/activities/delete-content`, true, reqBody, true),
  deleteActivity: (reqBody) => DeleteApi(`/activities/delete-activity`, true, reqBody),
  deleteActivityInOrg: (reqBody) => DeleteApi(`/whitelabel/activities/delete-activity`, true, reqBody, true),
  getPendingActivities: (contentId, page, limit, status, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'content_id', value: contentId },
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'status', value: status },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/activities/get-pending-activity${tmpUrl}`;

    return GetApi(newUrl, true);
  },
  getPendingActivitiesInOrg: (contentId, page, limit, status, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'content_id', value: contentId },
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'status', value: status },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/activities/get-pending-activity${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },
  getCommentsList: (contentId, page, limit, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'content_id', value: contentId },
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/activities/get-comment-list${tmpUrl}`;

    return GetApi(newUrl, true);
  },
  getCommentsListInOrg: (contentId, page, limit, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'content_id', value: contentId },
      { key: 'searchKey', value: searchKey },
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'sortOrder', value: sortOrder },
      { key: 'sortBy', value: sortBy },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/activities/get-comment-list${tmpUrl}`;

    return GetApi(newUrl, true, true);
  },

  // dashboard APIs
  getDashboardStats: (fDate, tDate) => {
    const params = [
      { key: 'from_date', value: fDate },
      { key: 'to_date', value: tDate },
    ];

    const tmpUrl = buildQueryString(params);

    if (window?.location?.pathname === '/whitelabel/admin/dashboard') {
      const newUrl = `/whitelabel/dashboard/get-dashboard${tmpUrl}`;
      return GetApi(newUrl, true, true);
    } else {
      const newUrl = `/dashboard/get-dashboard${tmpUrl}`;
      return GetApi(newUrl, true);
    }
  },
  getDashboardChartData: (reqBody) => PostApi('/dashboard/get-activty-chart-data', reqBody, true),
  getDashboardChartDataSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/dashboard/get-activty-chart-data', reqBody, true, undefined, true),
  getOfferRequestChartData: (reqBody) => PostApi('/dashboard/get-activty-status-chart-data', reqBody, true),
  getOfferRequestChartDataSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/dashboard/get-activty-status-chart-data', reqBody, true, undefined, true),
  getWhitelabelOrgList: (page, perPage, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/auth/get-whitelabel-org-list${tmpUrl}`;
    return GetApi(newUrl, true);
  },
  createWhitelabelOrg: (reqBody) => PostApi('/auth/create-whitelabel-org', reqBody, true),
  toggleOrgStatus: (reqBody) => PostApi('/auth/active-inactive-org', reqBody, true),
  updateOrgName: (reqBody) => PutApi('/auth/update-org', reqBody, true),
  authorizeWhiteLabelAdmin: (access_token, refresh_token, jwt_token) => {
    let url = `/whitelabel/auth/whitelabel-user-grants?access_token=${access_token}&refresh_token=${refresh_token}`;
    url += jwt_token ? `&jwt_token=${jwt_token}` : '';
    return PostApi(url, true);
  },
  getUsersOfOrg: (page, perPage, searchKey, sortBy, sortOrder, org_id) => {
    const params = [
      { key: 'page', value: page },
      { key: 'perPage', value: perPage },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
      { key: 'org_id', value: org_id },
    ];

    const tmpUrl = buildQueryString(params);
    const newUrl = `/whitelabel/auth/get-whitelabel-org-users${tmpUrl}`;
    return GetApi(newUrl, true, true);
  },
  toggleWhiteLabelAdminUser: (reqBody) =>
    PostApi(`/whitelabel/auth/active-inactive-whitelabel-user`, reqBody, true, undefined, true),
  updateOrganiztation: (id, reqBody) => {
    return PutApi(`/whitelabel/auth/whitelabel/update-org/${id}`, reqBody, true, true);
  },
  fetchOrganizationDetail: (id) => {
    return GetApi(`/whitelabel/auth/whitelabel/orgs/${id}`, true, true);
  },
  getAllPendingNetworkJoinRequest: (network_id, page, limit, searchKey, sortBy, sortOrder) => {
    const params = [
      { key: 'network_id', value: network_id },
      { key: 'page', value: page },
      { key: 'limit', value: limit },
      { key: 'searchKey', value: searchKey },
      { key: 'sortBy', value: sortBy },
      { key: 'sortOrder', value: sortOrder },
    ];

    const tmpUrl = buildQueryString(params);
    let newUrl;
    if (window?.location?.pathname == '/whitelabel/admin/networks/view') {
      newUrl = `/whitelabel/network/network-user-relation-pending-list${tmpUrl}&searchKey=${searchKey}`;
      return GetApi(newUrl, true, true);
    } else {
      newUrl = `/network/network-user-relation-pending-list${tmpUrl}&searchKey=${searchKey}`;
      return GetApi(newUrl, true);
    }
  },
  approveRejectRefApplicationForm: (reqBody) =>
    PostApi(`/network/network-user-relation-approved-decline-refer`, reqBody, true, undefined),
  approveRejectRefApplicationFormSpecificToOrg: (reqBody) =>
    PostApi(`/whitelabel/network/network-user-relation-approved-decline-refer`, reqBody, true, undefined, true),
  getFullApplicationFormDetailOfUser: (network_user_relation_id, network_id) => {
    GetApi(
      `/network/user-application-form-attachments?network_user_relation_id=${network_user_relation_id}&network_id=${network_id}`,
      true,
    );
  },
  getFullApplicationFormDetailOfUserSpecificToOrg: (network_user_relation_id, network_id) =>
    GetApi(
      `/whitelabel/network/user-application-form-attachments?network_user_relation_id=${network_user_relation_id}&network_id=${network_id}`,
      true,
      true,
    ),
  getFullApplicationFormDetailOfUser: (network_user_relation_id, network_id) =>
    GetApi(
      `/network/user-application-form-attachments?network_user_relation_id=${network_user_relation_id}&network_id=${network_id}`,
      true,
    ),
  getFullGraphData: (network_id, gender) => {
    const reqBody = {
      network_ids: network_id.length ? network_id : [],
      gender: gender.length ? gender : [],
    };
    return PostApi('/network/get-user-network-activity-exchanges', reqBody, true);
  },
  getFullGraphDataWhiteLabel: (network_id, gender) => {
    const reqBody = {
      network_ids: network_id.length ? network_id : [],
      gender: gender.length ? gender : [],
    };
    return PostApi('/whitelabel/network/get-user-network-activity-exchanges', reqBody, true, undefined, true);
  },
  getAvailableUserNetworkToReferSpecificToOrg: (userId, search) =>
    GetApi(`/whitelabel/network/get-available-user-networks?user_id=${userId}&searchKey=${search}`, true, true),
  getAvailableUserNetworkToRefer: (userId, search) =>
    GetApi(`/network/get-available-user-networks?user_id=${userId}&searchKey=${search}`, true),
  bulkApproveRejectReferSpecificToOrg: (networkId, reqBody) =>
    PostApi(
      `/whitelabel/network/approved-all-request-to-join-network?network_id=${networkId}`,
      reqBody,
      true,
      undefined,
      true,
    ),
  bulkApproveRejectRefer: (networkId, reqBody) =>
    PostApi(`/network/approved-all-request-to-join-network?network_id=${networkId}`, reqBody, true, undefined),
  getApplicationsHistory: (reqBody) => PostApi(`/network/network-user-relation-list`, reqBody, true, undefined),
  getApplicationsHistorySpecificToOrg: (reqBody) =>
    PostApi(`/whitelabel/network/network-user-relation-list`, reqBody, true, undefined, true),

  getNetworkNewNotificationSpecificToOrg: () => {
    return GetApi('/whitelabel/network/get-network-new-notifications', true, true);
  },
  getNetworkNewNotification: () => {
    return GetApi('/network/get-network-new-notifications', true);
  },
  updateNetworkNotificationsViewSpecificToOrg: (networkId, type) => {
    return PutApi(
      `/whitelabel/network/update-network-notifications-viewed?network_id=${networkId}&type=${type}`,
      undefined,
      true,
      true,
    );
  },
  updateNetworkNotificationsView: (networkId, type) => {
    return PutApi(`/network/update-network-notifications-viewed?network_id=${networkId}&type=${type}`, undefined, true);
  },
  authorizeSuperAdmin: (access_token, refresh_token, jwt_token) => {
    let url = `/auth/superadmin-user-grants?access_token=${access_token}&refresh_token=${refresh_token}`;
    url += jwt_token ? `&jwt_token=${jwt_token}` : '';
    return PostApi(url, true);
  },

  getApplicationHistoryToExportSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/network/export-network-user-joiners', reqBody, true, undefined, true),
  getApplicationHistoryToExport: (reqBody) => PostApi('/network/export-network-user-joiners', reqBody, true, undefined),

  getNetworkEmailTemplate: (network_id, trigger_event, whichRole) => {
    let url = `/network/get-network-email-template?trigger_event=${trigger_event}`;
    if (network_id) {
      url += `&network_id=${network_id}`;
    }
    if (whichRole) {
      url += `&sent_to=${whichRole}`;
    }
    return GetApi(url, true);
  },
  getNetworkEmailTemplateSpecificToOrg: (network_id, trigger_event, whichRole) => {
    let url = `/whitelabel/network/get-network-email-template?trigger_event=${trigger_event}`;
    if (network_id) {
      url += `&network_id=${network_id}`;
    }
    if (whichRole) {
      url += `&sent_to=${whichRole}`;
    }
    return GetApi(url, true, true);
  },

  updateNetworkEmailTemplate: (reqBody) => PutApi('/network/update-network-email-template', reqBody, true),
  updateNetworkEmailTemplateSpecificToOrg: (reqBody) =>
    PutApi('/whitelabel/network/update-network-email-template', reqBody, true, true),
  createNetworkEmailTemplateSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/network/create-network-email-template', reqBody, true, undefined, true),
  getImpersonateTokenByUserId: (userId, access_token) =>
    GetApi(`/auth/get-user-impersonation-details?user_id=${userId}&z_access_token=${access_token}`, true),
  getImpersonateTokenByUserIdSpecificToOrg: (userId, access_token) =>
    GetApi(
      `/whitelabel/auth/get-user-impersonation-details?user_id=${userId}&z_access_token=${access_token}`,
      true,
      true,
    ),
  getAllUserOfOrg: (page, perPage, searchTerm) =>
    GetApi(`/user/get-organization-all-users?page=${page}&perPage=${perPage}&searchKey=${searchTerm}`, true),
  getAllUserOfOrgSpecificToOrg: (page, perPage, searchTerm) =>
    GetApi(
      `/whitelabel/user/get-organization-all-users?page=${page}&perPage=${perPage}&searchKey=${searchTerm}`,
      true,
      true,
    ),
  notifyPanelReviewersThroughEmail: (reqBody) => PostApi('/network/notify-panel-reviewers', reqBody, true, undefined),
  notifyPanelReviewersThroughEmailSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/network/notify-panel-reviewers', reqBody, true, undefined, true),
  getGroupProfilePresignedUrlDetail: (reqBody) =>
    PostApi('/network/upload-group-profile-picture', reqBody, true, undefined),
  getGroupProfilePresignedUrlDetailSpecificToOrg: (reqBody) =>
    PostApi('/whitelabel/network/upload-group-profile-picture', reqBody, true, undefined, true),
};
