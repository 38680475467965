import { Fragment, useState, useContext } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { SidebarContext } from "../context/SidebarContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const subMenu = [];

const Menu = ({ item }) => {
  const location = useLocation();
  const history = useHistory();
  const { setShow } = useContext(SidebarContext);

  const getStatus = () => {
    return !!subMenu?.map((item) => item.href)?.includes(location.pathname);
  };

  const [showSubMenu, isShowSubMenu] = useState(getStatus());

  const handleRedirect = (link) => {
    if (window.innerWidth < 1024) {
      setShow(false);
    }
    history.push(link);
  };

  return (
    <>
      <div
        key={item.name}
        onClick={() => {
          if (item.href !== "#") {
            if (item.href === "isDropDown") {
              isShowSubMenu(!showSubMenu);
            } else {
              handleRedirect(item.href);
            }
          }
        }}
        className={classNames(
          location?.pathname?.includes(item.href)
            ? "!bg-gradient-to-r !from-hexitime-primary !to-hexitime-secondary !cursor-pointer"
            : "hover:!bg-hexitime-secondary hover:!bg-opacity-75 !cursor-pointer",
          "!group !outline-none !mx-2 !flex !text-[#F5F5F5] !items-center !py-3 !px-2 !text-sm !font-normal !rounded-md"
        )}
      >
        {item?.icon && !item?.directSvgPassed ? (
          <img
            src={item.icon}
            alt="sidebar_icon"
            className={`!mr-3 !ml-2 !h-6 !w-6 !flex-shrink-0 !text-white`}
          />
        ) : (
          item.icon
        )}
        {item.name}
        {item.isDropDown &&
          (showSubMenu ? (
            <ChevronUpIcon className="!w-[20px] !ml-2" />
          ) : (
            <ChevronDownIcon className="!w-[20px] !ml-2" />
          ))}
      </div>
      {item.isDropDown && showSubMenu && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="!text-white !py-2 !space-y-2 !px-2 !bg-hexitime-subMenuBackground">
            {item.subMenu?.map((innerItem, index) => {
              return !innerItem.show ? (
                ""
              ) : (
                <div
                  key={index}
                  onClick={() => handleRedirect(innerItem.href)}
                  className={classNames(
                    location?.pathname?.includes(innerItem.href)
                      ? "!bg-gradient-to-r !from-hexitime-primary !to-hexitime-secondary !cursor-pointer"
                      : "hover:!bg-hexitime-secondary hover:!bg-opacity-75 !cursor-pointer",
                    "!pl-[20px] !mx-4  !group !outline-none !flex !text-[#F5F5F5] !items-center !py-3 !px-2 !text-sm !font-normal !rounded-md"
                  )}
                >
                  {innerItem.name}
                </div>
              );
            })}
          </div>
        </Transition>
      )}
    </>
  );
};

Menu.propTypes = {
  item: PropTypes.any,
};

export default Menu;
