import { createContext, useReducer } from "react";

const initialValue = {
  totalPendingNotification: 0,
  networkWisePendingNotification: [],
};
const addOrUpdateNetworkWiseNotification = (
  allNetworkNotification,
  networkId
) => {
  if (allNetworkNotification?.findIndex((data) => data?.id == networkId) != -1) {
    return allNetworkNotification.map((data) => {
      return {
        ...data,
        new_application_pending_count:
          data.id == networkId
            ? data.new_application_pending_count + 1
            : data.new_application_pending_count,
      };
    });
  } else {
    return [...allNetworkNotification, { id: networkId, new_application_pending_count: 1 }];
  }
};
const notificationReducer = (state, action) => {
  switch (action.type) {
    case "NEW_PENDING_REQUEST": {
      return {
        ...state,
        totalPendingNotification: state.totalPendingNotification + 1,
        networkWisePendingNotification: addOrUpdateNetworkWiseNotification(
          state.networkWisePendingNotification,
          action.payload.networkId
        ),
      };
    }
    case "ALL_REQUEST": {
      return {
        ...state,
        totalPendingNotification: action?.payload?.networks?.reduce(
          (total, element) =>
            Number(element?.new_application_pending_count) + total,
          0
        ),
        networkWisePendingNotification: action?.payload?.networks,
      };
    }
  }
};

export const UpdatesContext = createContext({
  state: "",
  dispatch: () => {},
});

export const InstanceUpdatesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialValue);

  return (
    <UpdatesContext.Provider
      value={{ state, dispatch }}
    >
      {children}
    </UpdatesContext.Provider>
  );
};
