import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/20/solid";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import {
  cleanLocalStorageBasedOnRole,
  getLocalStorageItem,
} from "../utils/helper";
import LazyLoadImageProp from "../component/common/LazyLoadImage";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { useAuth } from "oidc-react";
import { UpdatesContext } from "../context/updatesContext";
import { Svgs } from "../component/common/Svgs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header({ forWhom = "" }) {
  const { isShow, setShow } = useContext(SidebarContext);
  const { state } = useContext(UpdatesContext);
  const { signOut } = useAuth();
  let userData, userProfilePic;
  if (forWhom == "whiteLabelAdmin") {
    const temp = JSON.parse(getLocalStorageItem("white_label_admin_detail"));
    if (temp?.orgInfo?.admin_user) {
      userData = {
        name: temp?.roles?.includes("networkadmin")
          ? temp?.networkInfo?.name
          : temp?.orgInfo?.admin_user?.name,
        user_type: 4,
      };
      userProfilePic = temp?.orgInfo?.admin_user?.profile_picture;
    }
  } else {
    userData =
      getLocalStorageItem("userData") &&
      JSON.parse(getLocalStorageItem("userData"));

    userProfilePic =
      (getLocalStorageItem("profilePicture") &&
        getLocalStorageItem("profilePicture")) ||
      null;
  }

  const handleLogout = async () => {
    cleanLocalStorageBasedOnRole(forWhom);
    signOut();
    forWhom == "whiteLabelAdmin"
      ? history.push("/whitelabel/admin/login")
      : history.push("/login");
  };

  const name = userData?.name?.split(" ");
  const history = useHistory();
  return (
    <>
      <div
        className={`px-4 h-[88px] py-2 border-b bg-white flex items-center justify-between fixed left-0 right-0 top-0 ${
          isShow ? "z-40" : "z-40"
        } lg:z-40`}
      >
        <div className="mb-4 flex items-center justify-between">
          {!isShow && (
            <Bars3Icon
              className="w-[25px] text-hexitime-customGray self-center mr-3 cursor-pointer"
              onClick={() => setShow(!isShow)}
            />
          )}
        </div>
        {state.totalPendingNotification > 0 && (
          <div
            class="flex w-[100%] justify-end items-center p-[29px] cursor-pointer relative"
            onClick={() =>
              history.push(
                forWhom == "whiteLabelAdmin"
                  ? "/whitelabel/admin/networks"
                  : "/networks"
              )
            }
          >
            <Svgs fillClass={" !h-[34px] !w-[34px]"} iconName={"bellIcon"} />
            <p class="absolute top-[15px] right-[22px] !rounded-full !h-6 !w-6 !bg-hexitime-primaryRed !p-1 !text-[0.75rem] !text-white !shadow-sm !focus-firstVisible:outline !flex !items-center !justify-center !ml-2">
              {state.totalPendingNotification}
            </p>
          </div>
        )}
        <div className="flex h-[88px]">
          <Menu as="div">
            <div className="flex">
              <Menu.Button className="w-full justify-center text-sm font-medium text-gray-700 focus:outline-none">
                <div className="flex h-[88px] relative items-center">
                  {userProfilePic && userProfilePic !== "null" ? (
                    <>
                      <LazyLoadImageProp
                        imageSrc={userProfilePic}
                        className="w-[50px] h-[50px] self-center rounded-full border border-[#EAEAEA]"
                      />
                    </>
                  ) : (
                    <div className="self-center bg-black flex justify-center items-center w-[50px] h-[50px] rounded-full">
                      <span className="text-white">
                        {name && name[0]?.charAt(0)}
                      </span>
                      <span className="text-white">
                        {name && name[1]?.charAt(0)}
                      </span>
                    </div>
                  )}
                  <div className="text-start self-center ml-3 md:block">
                    <p className="text-[#0B0F18] text-[18px] m-0">
                      {userData?.name}
                    </p>
                    <span className="text-hexitime-customGray font-light">
                      {userData?.user_type === 1
                        ? "Super Admin"
                        : userData?.user_type === 3
                        ? "Network Admin"
                        : "Admin"}
                    </span>
                  </div>
                  <ChevronDownIcon className="ml-3 w-[25px] text-hexitime-customGray" />
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute shadow-lg right-[20px] top-[90px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-hexitime-primary ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`${
                          forWhom == "whiteLabelAdmin"
                            ? "/whitelabel/admin/change-password"
                            : "/change-password"
                        }`}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Change Password
                      </Link>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={handleLogout}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  unreadNotiCount: PropTypes.any,
};

export default Header;
