import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CONTENT_TYPE } from "./constants";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { DEADOMAINS } from "./dea-domains";
import { AVATAR_COLORS_ARRAY } from "./constants";
import moment from "moment";

export const MaxCharlimit = 100;
export const MaxCharlimitLongText = 1000;
export const getLocalStorageItem = (key) => localStorage.getItem(key);
export const setLocalStorageItem = (key, value) =>
  localStorage.setItem(key, value);
export const removeLocalStorageItem = (key) => localStorage.removeItem(key);
export const cleanLocalStorage = () => localStorage.clear();
export const getJWTToken = () => "Bearer " + localStorage.getItem("token");
export const getJWTTokenWhiteLabelAdmin = () =>
  "Bearer " + localStorage.getItem("white_label_admin_access_token");
export const getDeviceToken = () => localStorage.getItem("deviceToken");
export const getZitadelAccessToken = () =>
  localStorage.getItem("white_label_admin_zitadel_access_token");
export const getUserType = (type) =>
  type === 1 ? "Super Admin" : type === 3 ? "Network Admin" : "Admin";
export const getFocusType = (type) => (type === 1 ? "Main" : "Affirmation");
export const getMeditationType = (type) => (type === 1 ? "Video" : "Audio");

export const getAccountType = (type) =>
  type === 0
    ? "In Trial"
    : type === 1
    ? "Not Subscribed"
    : type === 2
    ? "Subscribed"
    : type === 3
    ? "Expired"
    : "━━";

export const getContentApprovalStatus = (type) => {
  return (
    <p
      className={`inline-flex m-0 rounded-full ${
        type === 1
          ? "bg-green-100"
          : type === 2
          ? "bg-red-100"
          : "bg-orange-100"
      } px-4 py-[6px] text-sm leading-5 capitalize ${
        type === 1
          ? "text-green-400"
          : type === 2
          ? "text-red-400"
          : "text-orange-400"
      }`}
    >
      {type === 1 ? "Active" : type === 2 ? "Rejected" : "Draft"}
    </p>
  );
};

//COMMENTED FOR IN FUTURE USE
// let returnValue = '';
// // eslint-disable-next-line
// sentToUser?.map((item) => {
//   if (item?.value === parseInt(type)) {
//     returnValue = item.name;
//   }
// });
// return returnValue ? returnValue : '━━';

export const getContentType = (type) => {
  const foundItem = CONTENT_TYPE?.find(
    (item) => item?.value === parseInt(type)
  );
  return foundItem ? foundItem.name : "━━";
};

export const errorToast = (msg, toastId = "") =>
  toast.error(msg, {
    duration: 2000,
    id: toastId,
  });

export const successToast = (msg, duration = 2000) =>
  toast.success(msg, {
    duration,
  });

export const informativeToast = (msg, duration = 3000) =>
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 p-2">
          <div className="flex items-start">
            <div className="self-center">
              <InformationCircleIcon className="w-[24px] text-hexitime-secondary" />
            </div>
            <div className="ml-3 self-center">
              <p className="mt-1 text-gray-500">{msg}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration,
    }
  );

export const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const capitalize = (value) => {
  let lowerCase = value?.toLowerCase();
  return lowerCase?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter?.toUpperCase()
  );
};

export const getFilterKey = (value) => {
  let key = value?.match(/([A-Z]?[^A-Z]*)/g).slice(0, -1);
  let returnKey = key?.toString()?.replaceAll(",", " ");
  return capitalize(returnKey);
};

export const isSuperAdmin = () => {
  const userData =
    getLocalStorageItem("userData") &&
    JSON.parse(getLocalStorageItem("userData"));
  return userData.user_type;
};

export const DeadDomainEmail = (email) => {
  const emailDomain = "@" + email.split("@")[1];
  return DEADOMAINS.includes(emailDomain);
};

export const ValueToPercentage = (value, max) => {
  return (value * 100) / max;
};

export const IsLastIndex = (j, length) => {
  return j === length - 1;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const getFileType = (value) => {
  const fileExtension = value?.name?.split(".");
  const finalType = `${value?.type?.split("/")[0]}/${
    fileExtension[fileExtension?.length - 1]
  }`;
  return finalType;
};

export const extractIds = (items = []) => {
  const ids = [];

  if (items?.length > 0) {
    items?.map((item) => {
      if (item.user_id) {
        ids.push(item.user_id);
      } else {
        ids.push(item.id);
      }

      return null;
    });
  }

  return ids;
};

export const extractNames = (items = []) => {
  const names = [];

  if (items?.length > 0) {
    items?.map((item) => {
      if (item.name) {
        names.push(item.name);
      }

      return null;
    });
  }

  return names;
};

export const addToRemovedList = (removedList, selectedList) => {
  const updatedRemovedList = removedList?.filter((item) => {
    return selectedList?.some((selectedItem) => selectedItem?.id === item?.id);
  });

  return updatedRemovedList;
};

export const truncateDescription = (description, MAX_DESCRIPTION_LENGTH) => {
  if (description && description.length > MAX_DESCRIPTION_LENGTH) {
    return `${description.slice(0, MAX_DESCRIPTION_LENGTH)}....`;
  }
  return description;
};

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * AVATAR_COLORS_ARRAY?.length);
  return AVATAR_COLORS_ARRAY[randomIndex];
};

export const generateFilename = (name, startDate, endDate) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format("DMMMYY").toLowerCase();
    const formattedEndDate = moment(endDate).format("DMMMYY").toLowerCase();

    return `${name}_${formattedStartDate}_${formattedEndDate}.csv`;
  } else {
    return `${name}.csv`;
  }
};

export const cleanLocalStorageBasedOnRole = (ofWhom = "whiteLabelAdmin") => {
  if (ofWhom == "whiteLabelAdmin") {
    removeLocalStorageItem("white_label_admin_detail");
    removeLocalStorageItem("white_label_admin_access_token");
    removeLocalStorageItem("white_label_admin_zitadel_access_token");
    removeLocalStorageItem("white_label_admin_refresh_token");
    removeLocalStorageItem("redirect_to_login");
  } else {
    removeLocalStorageItem("token");
    removeLocalStorageItem("profilePicture");
    removeLocalStorageItem("userData");
    removeLocalStorageItem("superadmin_zitadel_access_token");
    removeLocalStorageItem("superadmin_zitadel_refresh_token");
  }
};

export const getTokenBasedOnRole = (isWhiteLabelAdminUserApi) => {
  return isWhiteLabelAdminUserApi
    ? getJWTTokenWhiteLabelAdmin()
    : getJWTToken();
};

export const getBaseUrl = (pathname, lastUrlElement = "/networks") => {
  if (pathname.includes("/whitelabel/admin/")) {
    return `/whitelabel/admin${lastUrlElement}`;
  } else {
    return lastUrlElement;
  }
};

export const whitelabelNetworkAdminAllowedRoute = [
  "/whitelabel/admin/networks",
  "/whitelabel/admin/networks/add-edit",
  "/whitelabel/admin/networks/view",
  "/whitelabel/admin/networks/view/application-form-detail",
];

export const base64toBlob = (base64Data, type) => {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {
    type: type,
  });
};

export const getAllowedFieldsTabWise = (key, fieldName) => {
  switch (key) {
    case "display": {
      if (fieldName == "content") {
        return [];
      } else if (fieldName == "tabs") {
        return ["label", "components"];
      } else {
        return ["label", "placeholder", "description"];
      }
    }
    case "conditional": {
      return ["simple-conditional"];
    }
    case "validation": {
      return ["validateOn", "validate.required"];
    }
    case "data": {
      return ["values", "data.values"];
    }
    case "file": {
      return ["storage", "useMultipartUpload", "fileMinSize", "fileMaxSize"];
    }
    default:
      return [];
  }
};

export const getLabelFromApplicationStatusNumber = (statusNumber) => {
  switch (statusNumber) {
    case "0":
      return "Pending";
    case "1":
      return "Accepted";
    case "2":
      return "Rejected";
    case "3":
      return "Referred";
    case "4":
      return "User Left Himself";
    default:
      return "Admin Removed User";
  }
};

export const getProperLable=(label)=>{
  switch(label){
    case "user":
        return "User"
    case "networkadmin":
        return "Network Admin"
    case "admin":
        return "Admin"
    case "superadmin":
        return "Superadmin"
  }
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
