import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import {
  getLocalStorageItem,
  getZitadelAccessToken,
  whitelabelNetworkAdminAllowedRoute,
} from "../utils/helper";
import { SidebarContext } from "../context/SidebarContext";
import Header from "../layout/Header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "oidc-react";
import LoaderWithLabel from "../component/common/LoaderWithLabel";

const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  const { isShow } = useContext(SidebarContext);
  const [authorizationCheckLoading, setAuthorizationCheckLoading] =
    useState(true);
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    setAuthorizationCheckLoading(true);
    const zitadelToken = getZitadelAccessToken();
    const access_token = localStorage.getItem("white_label_admin_access_token");
    let detail = getLocalStorageItem("white_label_admin_detail");
    if (!zitadelToken && !access_token && !detail) {
      auth.signIn({ prompt: "login" });
    } else if (zitadelToken && access_token && detail) {
      detail = JSON.parse(detail);
      if (
        detail?.roles?.includes("admin") ||
        detail?.roles?.includes("superadmin")
      ) {
        setAuthorizationCheckLoading(false);
      } else if (detail?.roles?.includes("networkadmin")) {
        if (
          !whitelabelNetworkAdminAllowedRoute.includes(window.location.pathname)
        ) {
          history.push(whitelabelNetworkAdminAllowedRoute[0]);
        }
        setAuthorizationCheckLoading(false);
      } else {
        history.push("/whitelabel/admin/error");
      }
    } else {
      history.push("/whitelabel/admin/error");
    }
  }, []);

  return (
    <>
      {authorizationCheckLoading ? (
        <LoaderWithLabel label="Authorization Processing" />
      ) : (
        <>
          <Header forWhom="whiteLabelAdmin" />
          <div id="main" className="main">
            <div
              className={`${
                !isShow ? "w-full" : "lg:w-[calc(100%-260px)]"
              } !px-4 !pt-4 !pb-8 lg:ml-auto mt-[88px]`}
            >
              <Route
                {...rest}
                render={(props) => {
                  return <Component {...props} />;
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminPrivateRoute;
