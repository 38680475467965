import React, { useEffect, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { cleanLocalStorage, getLocalStorageItem } from "../utils/helper";
import Header from "../layout/Header";
import { SidebarContext } from "../context/SidebarContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isShow } = useContext(SidebarContext);

  const userData =
    getLocalStorageItem("userData") &&
    JSON.parse(getLocalStorageItem("userData"));

  useEffect(() => {
    if (!getLocalStorageItem("token")) {
      cleanLocalStorage();
    }
  }, []);

  const isAccessible = (path) => {
    const isAdminURLList = ["/admins", "/admins/add-edit"];

    if (isAdminURLList.includes(path)) {
      if (userData.user_type !== 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isNetworkAccessible = (path) => {
    const isAdminURLList = [
      "/networks",
      "/networks/view",
      "/networks/add-edit",
      "/offers/view",
      "/requests/view",
      "/threads/view",
      "/change-password",
    ];

    if (isAdminURLList.includes(path)) {
      if (userData.user_type === 3) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {getLocalStorageItem("token") && <Header />}
      <div id="main" className="main">
        <div
          className={`${
            !isShow ? "w-full" : "lg:w-[calc(100%-260px)]"
          } !px-4 !pt-4 !pb-8 lg:ml-auto mt-[88px]`}
        >
          <Route
            {...rest}
            render={
              (props) =>
                getLocalStorageItem("token") ? (
                  userData?.user_type !== 3 ? (
                    isAccessible(rest?.path) ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Component {...props} />
                    )
                  ) : isNetworkAccessible(rest?.path) ? (
                    <Component {...props} />
                  ) : (
                    <Redirect to="/networks" />
                  )
                ) : (
                  <Redirect to="/login" />
                )

              // getLocalStorageItem("token") ? (
              //   isAccessible(rest?.path) ? (
              //     userData?.user_type !== 3 ? (
              //       <Redirect to="/dashboard" />
              //     ) : (
              //       <Redirect to="/networks" />
              //     )
              //   ) : (
              //     <Component {...props} />
              //   )
              // ) : (
              //   <Redirect to="/login" />
              // )
            }
          />
        </div>
      </div>
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  rest: PropTypes.object,
};

export default PrivateRoute;
