export const DEVICE_TYPE = 3;
export const MAX_DESCRIPTION_LENGTH = 290;
export const MAX_DESCRIPTION_LENGTH_RICH_TEXT = 400;

export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png'];

export const USER_TYPE = [
  { name: 'Select type', value: '' },
  { name: 'Super Admin', value: 1 },
  { name: 'Admin', value: 2 },
  { name: 'Network Admin', value: 3 },
];

export const EMAIL_VERIFY = [
  { name: 'Select email status', value: '' },
  { name: 'Verified', value: 1 },
  { name: 'Pending', value: 0 },
];

export const PER_PAGE = [
  { name: '10', value: 10 },
  { name: '25', value: 25 },
  { name: '50', value: 50 },
  { name: '100', value: 100 },
];

export const CONTENT_APPROVAL_STATUS = [
  { name: 'Approve', value: 1 },
  { name: 'Reject', value: 2 },
  { name: 'Draft', value: 0 },
];

export const APPROVAL_STATUS_DROPDOWN = [
  { name: 'Select approval status', value: '' },
  { name: 'Approve', value: 1 },
  { name: 'Reject', value: 2 },
  { name: 'Draft', value: 0 },
];

export const STATUS = [
  { name: 'Active', value: 1 },
  { name: 'Inactive', value: 0 },
];

export const STATUS_FOR_DROPDOWN = [
  { name: 'Select status', value: '' },
  { name: 'Active', value: 1 },
  { name: 'Inactive', value: 0 },
];

export const MEDIA_TYPE = [
  { name: 'Audio', value: 1 },
  { name: 'Video', value: 2 },
];

export const CONTENT_TYPE_WITH_TEXT = [
  { name: 'Text', value: 1 },
  { name: 'Image', value: 2 },
  { name: 'Video', value: 3 },
];

export const MEDITATION_BY = [
  { name: 'Hexitime', value: 1 },
  { name: 'Expert', value: 2 },
];

export const CONTENT_TYPE = [
  { name: 'Select content type', value: '' },
  { name: 'Focus', value: 1 },
  { name: 'Affirmation', value: 2 },
  { name: 'Meditation', value: 3 },
  { name: 'Sound', value: 4 },
  { name: 'Hexitime Pod', value: 5 },
  { name: 'Gratitude', value: 6 },
  { name: 'Rituals', value: 7 },
];

export const CONTENT_APPROVAL_TYPE = [
  { name: 'Latest version', value: 2 },
  { name: 'Previous version', value: 1 },
];

export const PERFORMANCE_CONTENT_TYPE = [
  { name: 'Cleanse', value: 1 },
  { name: 'Gratitude', value: 2 },
  { name: 'Goals', value: 3 },
  { name: 'Rituals', value: 4 },
  { name: 'Notes', value: 5 },
  { name: 'Badges', value: 6 },
];

export const USER_FEELS = [
  { name: 'Daily', value: 1 },
  { name: 'Weekly', value: 2 },
  { name: 'Monthly', value: 3 },
  { name: 'Yearly', value: 4 },
];

export const COLORS = [
  'rgba(218, 60, 60, 0.7)',
  'rgba(235, 128, 50, 0.7)',
  'rgba(133, 225, 61, 0.7)',
  'rgba(61, 225, 186, 0.7)',
  'rgba(227, 221, 57, 0.7)',
  'rgba(139, 60, 218, 0.7)',
  'rgba(50, 57, 235, 0.7)',
  'rgba(183, 61, 225, 0.7)',
  'rgba(128, 144, 203, 0.7)',
  'rgba(158, 36, 139, 0.7)',
  'rgba(54, 169, 251, 0.7)',
  'rgba(254, 170, 95, 0.7)',
  'rgba(245, 105, 107, 0.7)',
  'rgba(168, 71, 94, 0.7)',
  'rgba(188, 143, 176, 0.7)',
  'rgba(243, 217, 147, 0.7)',
  'rgba(141, 0, 9, 0.7)',
  'rgba(253, 80, 26, 0.7)',
  'rgba(207, 210, 58, 0.7)',
  'rgba(155, 182, 196, 0.7)',
  'rgba(93, 116, 97, 0.7)',
  'rgba(71, 142, 15, 0.7)',
  'rgba(248, 96, 124, 0.7)',
  'rgba(137, 172, 249, 0.7)',
  'rgba(251, 240, 111, 0.7)',
];

export const PASSWORD_VALIDATION = {
  minLength: 8,
  minLowerCase: 1,
  minUpperCase: 1,
  minNumbers: 1,
  minSymbols: 0,
  returnScore: false,
};

export const IMAGES_ARR = [
  {
    id: 1,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner1.jpg',
    current: true,
  },
  {
    id: 2,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner2.jpg',
    current: false,
  },
  {
    id: 3,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner3.jpg',
    current: false,
  },
  {
    id: 4,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner4.jpg',
    current: false,
  },
  {
    id: 5,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner5.jpg',
    current: false,
  },
  {
    id: 6,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner6.jpg',
    current: false,
  },
  {
    id: 7,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner7.jpg',
    current: false,
  },
  {
    id: 8,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner8.jpg',
    current: false,
  },
  {
    id: 9,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner9.jpg',
    current: false,
  },
  {
    id: 10,
    path: 'https://staging-media-app.hexitime.com/content-banners/Banner10.jpg',
    current: false,
  },
  {
    id: 11,
    path: 'https://staging-media-app.hexitime.com/content-banners/banner11.jpeg',
    current: false,
  },
  {
    id: 12,
    path: 'https://staging-media-app.hexitime.com/content-banners/banner12.jpeg',
    current: false,
  },
];

export const AGE_RANGE_MENU = [
  { name: 'Select', value: '' },
  { name: '18-24', value: '18-24' },
  { name: '25-34', value: '25-34' },
  { name: '35-44', value: '35-44' },
  { name: '45-54', value: '45-54' },
  { name: '55-64', value: '55-64' },
  { name: '65+', value: '65+' },
];

export const GENDER_MENU = [
  { name: 'Select', value: '' },
  { name: 'Male', value: 'Male' },
  { name: 'Female', value: 'Female' },
  { name: 'Intersex', value: 'Intersex' },
  { name: 'Non-binary', value: 'Non-binary' },
];

export const AVATAR_COLORS_ARRAY = [
  { textColor: '#177E9C', bgColor: '#E3F7FC' },
  { textColor: '#177E9C', bgColor: '#BFF3FF' },
  { textColor: '#ffffff', bgColor: '#53919E' },
  { textColor: '#ffffff', bgColor: '#244850' },
  { textColor: '#177E9C', bgColor: '#AFEFE4' },
  { textColor: '#ffffff', bgColor: '#366C5C' },
  { textColor: '#ffffff', bgColor: '#245043' },
];

export const builderOptions = {
  noDefaultSubmitButton: true,
  builder: {
    advanced: false,
    basic: false,
    data: false,
    layout: false,
    premium: false,
    customeBasic: {
      title: 'Form Elements',
      default: true,
      weight: 0,
      components: {
        textfield: true,
        textarea: true,
        email: true,
        select: true,
        selectboxes: true,
        select: true,
        radio: true,
        button: true,
        content: true,
        tabs: true,
        number: true,
        datetime: true,
        file: {
          title: 'file',
          key: 'file',
          icon: 'file',
          schema: {
            label: 'file',
            type: 'file',
            key: 'file',
            input: true,
            storage: 'base64',
            fileMinSize: '1KB',
            fileMaxSize: '5MB',
          },
        },
      },
    },
  },
};
