import { useContext, useEffect, useRef } from "react";
import { UpdatesContext } from "../context/updatesContext";

export const useConnectWebSocket = ({ flag }) => {
  const socketRef = useRef("");
  const reconnectClosedConnectionRef = useRef("");
  const { dispatch } = useContext(UpdatesContext);
  const { REACT_APP_WEBSOCKET_ENDPOINT, REACT_APP_RECONNECT_SOCKET_IN_SECOND } =
    process.env;
  const connectWebSocket = () => {
    let token=localStorage.getItem("token");
    const temp=()=>{
      socketRef.current = new WebSocket(
        `${REACT_APP_WEBSOCKET_ENDPOINT}?token=${token}`
      );
      socketRef.current.onopen = () => {
        console.log("socket connected....");
      };
      socketRef.current.onmessage = (event) => {
        const newRequest = JSON.parse(event.data);
        if (newRequest.id) {
          dispatch({
            type: "NEW_PENDING_REQUEST",
            payload: { networkId: newRequest.id },
          });
        }
      };
      socketRef.current.onclose = () => {
        console.log("connection is closed");
        reconnectClosedConnectionRef.current = setTimeout(
          connectWebSocket,
          REACT_APP_RECONNECT_SOCKET_IN_SECOND * 1000
        );
      };
      socketRef.current.onerror = (error) => {
        console.log("websocket error", error);
        socketRef.current.close();
      };
    }
    if (window?.location?.pathname?.includes("/whitelabel/admin/")) {
      token = localStorage.getItem("white_label_admin_access_token");
      token && temp()
    }
    else if(token)
    {
      temp()
    }
  };
  useEffect(() => {
    if (flag) {
      connectWebSocket();
    }
    return () => {
      if (socketRef.current) {
        console.log("connection is closed...");
        socketRef.current.close();
      }
      if (reconnectClosedConnectionRef.current) {
        clearTimeout(reconnectClosedConnectionRef.current);
      }
    };
  }, [flag]);
};
