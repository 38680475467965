import { lazy } from "react";

const Login = lazy(() => import("../container/Login"));
const ForgotPassword = lazy(() => import("../container/ForgotPassword"));
const ResetPassword = lazy(() => import("../container/ResetPassword"));
const ChangePassword = lazy(() => import("../container/ChangePassword"));
const Dashboard = lazy(() => import("../container/Dashboard"));
const SubAdmin = lazy(() => import("../container/SubAdmin/SubAdmin"));
const AddEditSubAdmin = lazy(() =>
  import("../container/SubAdmin/AddEditSubAdmin")
);
const Users = lazy(() => import("../container/Users/Users"));
const ViewEditUser = lazy(() => import("../container/Users/ViewEditUser"));

const Networks = lazy(() => import("../container/Networks/Networks"));
const AddEditNetwork = lazy(() =>
  import("../container/Networks/AddEditNetwork")
);

const Offers = lazy(() => import("../container/Offers/Offers"));
const ViewOffer = lazy(() => import("../container/Offers/ViewOffer"));

const Requests = lazy(() => import("../container/Requests/Requests"));
const ViewRequest = lazy(() => import("../container/Requests/ViewRequest"));

const Threads = lazy(() => import("../container/Threads/Threads"));
const ViewThread = lazy(() => import("../container/Threads/ViewThread"));
const WhiteLabelInsertEditForm = lazy(() =>
  import("../container/WhiteLabel/AddEdit")
);
const WhiteLabelCustomerList = lazy(() =>
  import("../container/WhiteLabel/List")
);
const ApplicationFormFullDetail = lazy(() =>
  import("../container/Networks/NetworkTabs/ApplicationFormFullDetail")
);
const EmailTemplate = lazy(() => import("../container/template/emailTemplate"));

const routes = [
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
    private: false,
  },
  {
    path: "/login/success",
    exact: true,
    name: "Login",
    component: Login,
    private: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
    private: false,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    private: false,
  },
  {
    path: "/change-password",
    exact: true,
    name: "ChangePassword",
    component: ChangePassword,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
    private: true,
    onlyAdmin: false,
  },

  {
    path: "/admins",
    exact: true,
    name: "SubAdmin",
    component: SubAdmin,
    private: true,
    onlyAdmin: true,
  },
  {
    path: "/admins/add-edit",
    exact: true,
    name: "AddEditSubAdmin",
    component: AddEditSubAdmin,
    private: true,
    onlyAdmin: true,
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    component: Users,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/users/view",
    exact: true,
    name: "ViewEditUser",
    component: ViewEditUser,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/users/edit",
    exact: true,
    name: "ViewEditUser",
    component: ViewEditUser,
    private: true,
    onlyAdmin: false,
  },

  {
    path: "/networks",
    exact: true,
    name: "Networks",
    component: Networks,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/networks/add-edit",
    exact: true,
    name: "AddEditNetwork",
    component: AddEditNetwork,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/networks/view",
    exact: true,
    name: "ViewNetwork",
    component: AddEditNetwork,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/offers",
    exact: true,
    name: "Offers",
    component: Offers,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/offers/view",
    exact: true,
    name: "ViewOffer",
    component: ViewOffer,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/requests",
    exact: true,
    name: "Requests",
    component: Requests,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/requests/view",
    exact: true,
    name: "ViewRequest",
    component: ViewRequest,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/threads",
    exact: true,
    name: "Threads",
    component: Threads,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/threads/view",
    exact: true,
    name: "ViewThread",
    component: ViewThread,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/whitelabel/customer/add",
    exact: true,
    name: "addOrganization",
    component: WhiteLabelInsertEditForm,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/whitelabel/customer",
    exact: true,
    name: "listOrganization",
    component: WhiteLabelCustomerList,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/whitelabel/customer/edit",
    exact: true,
    name: "addOrganization",
    component: WhiteLabelInsertEditForm,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/networks/view/application-form-detail",
    exact: true,
    name: "ViewNetwork",
    component: ApplicationFormFullDetail,
    private: true,
    onlyAdmin: false,
  },
  {
    path: "/template",
    exact: true,
    name: "viewEditTemplate",
    component: EmailTemplate,
    private: true,
    onlyAdmin: false,
  },
];
export default routes;
